@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply flex flex-col items-center;
}

#root {
  @apply w-full;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  @apply p-4 outline-none border border-gray-300 my-1;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-table-wrapper .ant-table-pagination-right {
  justify-content: normal;
  justify-content: center;
}
